<template>
  <div v-loading="form.isLoading" class="Card has-background-white">
    <div class="Card__body">
      <heading size="4">
        {{ $t('pages.password_recovery.title') }}
      </heading>
      <h2>{{ $t('pages.password_recovery.fill_in_new_password') }}</h2>

      <form class="m-t-l" @submit.prevent="submit">
        <form-field
          :label="$t('pages.password_recovery.new_password')"
          :validator="$v.form.password"
          :attribute="$t('forms.password')"
        >
          <input
            v-model="form.password"
            class="input"
            name="password"
            type="password"
            @input="$v.form.password.$touch()"
          >
        </form-field>

        <form-field
          :label="$t('pages.password_recovery.confirm_password')"
          :validator="$v.form.password_confirmation"
          :attribute="$t('pages.password_recovery.password_confirmation')"
        >
          <input
            v-model="form.password_confirmation"
            class="input"
            name="password"
            type="password"
            @input="$v.form.password_confirmation.$touch()"
          >
        </form-field>

        <div class="links">
          <v-button type="submit" class="is-black is-large m-b-m">
            {{ $t('forms.update') }}
          </v-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Form from '@/services/forms/Form'
import { required, sameAs } from 'vuelidate/lib/validators'
import { isPasswordStrongEnough } from '@hypefactors/shared/js/utils/validation'

export default {
  data: () => ({
    form: new Form({
      password: { value: '', rules: { required } },
      password_confirmation: { value: '', rules: { required, same_as: sameAs('password'), 'password.strength': isPasswordStrongEnough(6) } },
      user_id: { value: '', rules: { required } },
      code: { value: '', rules: { required } }
    })
  }),

  validations () {
    return {
      form: this.form.rules()
    }
  },

  methods: {
    submit () {
      this.form.user_id = this.$route.params.user_id
      this.form.code = this.$route.params.code

      this.$v.form.$touch()

      if (this.$v.form.$error) {
        return
      }

      this.form.post('/passwords/reset')
        .then(data => {
          this.$notify({
            title: this.$t('general.success'),
            message: this.$t('success.password_changed'),
            type: 'success'
          })
          this.form.reset()
          this.$v.form.$reset()
          this.$router.push({ name: 'login' })
        })
        .catch(error => {
          this.$displayRequestError(error)
          this.form.reset()
          this.$v.form.$reset()
          this.$router.push({ name: 'login' })
        })
    }
  }
}
</script>
